import { Button, Modal, ModalContent, useDisclosure } from '@nextui-org/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Slider from 'react-slick';

import { useScreenWidth } from '../ScreenWidthProvider';

// Optimize image by importing them directly for better automatic image optimization
export const useHeroSlidesData = () => {
  const router = useRouter();
  const silenceRetreatData = {
    id: 6,
    mobileImg: `${router.basePath}/assets/images/Silent_retreat_SlideMobile.jpg`,
    titlePart1: 'Silent Retreat ',
    titlePart2: 'with Gurudev',
    desc: 'Experience profound rejuvenation at mountaintop Silent Retreat with Gurudev, ideal for newcomers to silent retreats and SKY Breath practice.',
    btnText: 'Know More',
    btnLink:
      'https://artoflivingretreatcenter.org/event/stepping-into-silence-with-gurudev/',
    target: '_blank',
  };
  const getslidesData = () => {
    return [
      {
        id: 2,
        img: `${router.basePath}/assets/images/hero-slide5.webp`,
        mobileImg: `${router.basePath}/assets/images/hero-slide-mobile5.webp`,
        titlePart1: 'Stepping Into Silence',
        titlePart2: 'with Gurudev',
        info: 'Dec 18-22 • Boone, NC',
        desc: '',
        btnText: 'Register',
        btnLink:
          'https://artoflivingretreatcenter.org/event/stepping-into-silence-with-gurudev/',
        target: '',
        slideClass: 'stepping-into-silence',
      },
      {
        id: 3,
        img: `${router.basePath}/assets/images/hero-slide2.webp`,
        mobileImg: `${router.basePath}/assets/images/hero-slide-mobile2.webp`,
        titlePart1: 'Discover Gurudev’s',
        titlePart2: 'ancient secret to modern well-being',
        info: 'Art of Living Part 1 Course',
        desc: '  Regain your calm with evidence-based practices in this life-changing course',
        btnText: 'Learn More',
        btnLink:
          'https://event.us.artofliving.org/us-en/artoflivingpart1/?utm_source=organic&utm_medium=home&utm_content=banner&course_id=811569',
        target: '',
      },
      {
        id: 4,
        img: `${router.basePath}/assets/images/hero-slide3.webp`,
        mobileImg: `${router.basePath}/assets/images/hero-slide-mobile3.webp`,
        titlePart1: 'A voice for ',
        titlePart2: 'harmony in diversity',
        info: 'Gurudev Sri Sri Ravi Shankar',
        desc: 'Spiritual leader, humanitarian, global ambassador of peace, and founder of the Art of Living',
        btnText: 'Know More',
        btnLink: 'https://www.artofliving.org/us-en/gurudev',
        target: '',
      },
      {
        id: 5,
        img: `${router.basePath}/assets/images/hero-slide4.webp`,
        mobileImg: `${router.basePath}/assets/images/hero-slide-mobile4.webp`,
        titlePart1: 'Questions about',
        titlePart2: 'love, grief, dealing with change?',
        info: 'Get the National bestseller',
        desc: '  Be inspired with new insights into how to deal with life’s biggest challenges',
        btnText: 'Know More',
        btnLink: 'https://a.co/d/4ZQztg2',
        target: '_blank',
      },
    ];
  };

  return {
    getslidesData,
    silenceRetreatData,
  };
};

export interface HeroSlidePropsTypes {
  slideClass?: string;
  id: number;
  img?: string;
  mobileImg?: string;
  playIcon?: string;
  mobilePlayIcon?: string;
  titlePart1: string;
  titlePart2?: string;
  info?: string;
  subInfo?: string;
  desc?: string;
  btnText?: string;
  btnLink?: string;
  target?: string;
}

const Slide = (data: HeroSlidePropsTypes) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <section
      className="flex"
      // style={{
      //   backgroundImage: `url(${data?.img})`,
      //   backgroundSize: '100% 100%',
      // }}
    >
      <div
        className={`${data?.slideClass || ''} relative aspect-[1140/530] size-full`}
      >
        {data?.img && (
          <Image
            className="object-cover"
            fill
            src={`${data?.img}`}
            alt={`${data?.titlePart1} ${data?.titlePart2}`}
            quality={100}
            loading="eager"
          />
        )}
        {/* Added priority in this place for better page loading as the page is the first load */}
        <div className="absolute flex h-full w-[650px] flex-col items-start justify-center pl-[76px] text-[#FFF9E4]">
          <div className="font-work-sans text-[56px] font-bold leading-[50px] ">
            <p className={`${data.id === 1 ? 'font-bold' : 'font-normal'}`}>
              {' '}
              {data?.titlePart1}
            </p>
            <p className="-mt-1 "> {data?.titlePart2}</p>
          </div>
          <p className="mt-[36px] text-[18px] font-bold ">{data?.info}</p>
          <p className=" text-[18px] font-bold ">{data?.subInfo}</p>
          <p className="my-[24px] w-[60%] text-[18px] font-normal">
            {data?.desc}
          </p>
          <a href={data?.btnLink} target={data?.target}>
            <Button
              className="h-[62px] w-[300px] bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest"
              color="primary"
            >
              {data?.btnText} <FaArrowRight />
            </Button>
          </a>
        </div>
      </div>
      {data?.playIcon && (
        <Modal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          size="5xl"
          hideCloseButton
        >
          <ModalContent>
            {(onClose) => (
              <div className="relative">
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center opacity-75">
                    <div className="size-12 animate-spin rounded-full border-x-4 border-x-[#FF9200]" />
                  </div>
                )}
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div
                  className="absolute right-5 top-0 z-10 size-[20px] cursor-pointer rounded-full p-4 text-white"
                  onClick={onClose}
                  onKeyUp={onClose}
                  role="button"
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="#FFFFFF"
                  >
                    <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
                  </svg>
                </div>
                <div className="aspect-h-9 aspect-w-16">
                  <iframe
                    width={window.innerWidth}
                    height="700"
                    src="https://www.youtube.com/embed/NAx9L5uTdqg?autoplay=1"
                    onLoad={handleIframeLoad}
                    title="World Culture Festival IV"
                    allow="autoplay; fullscreen; picture-in-picture"
                  />
                </div>
              </div>
            )}
          </ModalContent>
        </Modal>
      )}
      {data?.playIcon && (
        <div className="absolute left-[45%] top-[20%] cursor-pointer">
          <Image
            src={`${data.playIcon}`}
            alt="Play icon"
            width={560} // Specify the width
            height={315} // Specify the height
            onClick={onOpen}
            quality={100}
          />
        </div>
      )}
    </section>
  );
};
const MobileSlide = (data: HeroSlidePropsTypes) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <section
      className="h-[700px]"
      // style={{
      //   backgroundImage: `url(${data?.mobileImg})`,
      //   backgroundSize: '100% 100%',
      // }}
    >
      <div className={`${data?.slideClass || ''} relative size-full`}>
        <Image
          className="object-cover object-top"
          fill
          src={`${data?.mobileImg}`}
          alt={`${data?.titlePart1} ${data?.titlePart2}`}
          quality={100}
          loading="eager"
        />
        {data?.playIcon && (
          <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center">
            <ModalContent>
              {(onClose) => (
                <div className="relative">
                  {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center opacity-75">
                      <div className="size-12 animate-spin rounded-full border-x-4 border-x-[#FF9200]" />
                    </div>
                  )}
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div
                    className="absolute right-5 top-0 z-10 size-[20px] cursor-pointer rounded-full p-4 text-white"
                    onClick={onClose}
                    onKeyUp={onClose}
                    role="button"
                    tabIndex={0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="25"
                      height="25"
                      viewBox="0 0 50 50"
                      fill="#FFFFFF"
                    >
                      <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
                    </svg>
                  </div>
                  <div className="aspect-h-9 aspect-w-16">
                    <iframe
                      width={window.innerWidth}
                      height="700"
                      src="https://www.youtube.com/embed/NAx9L5uTdqg?autoplay=1"
                      onLoad={handleIframeLoad}
                      title="World Culture Festival IV"
                      allow="autoplay; fullscreen; picture-in-picture"
                    />
                  </div>
                </div>
              )}
            </ModalContent>
          </Modal>
        )}
        {data?.playIcon && (
          <div className="absolute left-[40%] top-[15%] m-auto cursor-pointer">
            <Image
              src={`${data?.mobilePlayIcon}`}
              onClick={onOpen}
              alt="Play icon"
              width={68} // Specify the width
              height={68} // Specify the height
            />
          </div>
        )}
        <div className="absolute bottom-0 mt-auto px-[31px] pb-[100px] text-[white]">
          <div className="font-work-sans text-[36px] font-bold leading-[36px] ">
            <p className={`${data.id !== 1 ? 'font-normal' : ''}`}>
              {data?.titlePart1}
            </p>
            <p className="-mt-1 "> {data?.titlePart2}</p>
          </div>
          <p className="mt-[15px] text-[18px] font-bold ">{data?.info}</p>
          <p className=" text-[18px] font-bold ">{data?.subInfo}</p>
          <p className="my-4  text-[18px] font-normal">{data?.desc}</p>
          <a href={data?.btnLink} target={data?.target} className="w-full">
            <Button
              className="mt-1 h-[62px]   bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest text-[white]"
              fullWidth
            >
              {data?.btnText} <FaArrowRight />
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
};

// const BooneDesktopSlide = () => {
//   const router = useRouter();
//   return (
//     <div className="relative aspect-[1140/530] size-full h-full">
//       <Image
//         className="object-cover"
//         fill
//         src={`${router.basePath}/assets/images/boonSlideDesktopImage.jpg`}
//         alt="programs with gurudev in Boone"
//         quality={100}
//         loading="eager"
//       />
//       {/* Added priority in this place for better page loading as the page is the first load */}
//       <div className="absolute right-0 h-full w-[650px] pl-[76px] pt-[90px] text-[#FFF9E4]">
//         <div className="font-work-sans text-[56px] font-bold leading-[50px] ">
//           <p className="font-normal">Programs with </p>
//           <p> Gurudev in Boone</p>
//         </div>
//         <div className="mt-8 flex items-center gap-6">
//           <a href="https://artoflivingretreatcenter.org/sri-sri-ravi-shankar/gurudev-july-2024">
//             <Button
//               className="h-[62px] w-[300px] bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest"
//               color="primary"
//             >
//               Know more <FaArrowRight />
//             </Button>
//           </a>
//           <div className=" max-w-fit">
//             <p className="font-work-sans text-[24px] font-medium">
//               July 14-22, 2024
//             </p>
//             <p className="text-center font-bold leading-5  text-[#FF9200]">
//               REGISTRATION <br />
//               NOW OPEN
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// const BooneMobileSlide = () => {
//   const router = useRouter();
//   return (
//     <section className="h-[700px]">
//       <div className="relative aspect-[1140/530] size-full ">
//         <Image
//           className="object-cover object-top"
//           fill
//           src={`${router.basePath}/assets/images/boonSlideMobileImage.jpg`}
//           alt="programs with gurudev in Boone"
//           quality={100}
//           loading="eager"
//         />
//         {/* Added priority in this place for better page loading as the page is the first load */}
//         <div className="absolute bottom-0  mt-auto px-[31px] pb-[100px] text-[#FFF9E4]">
//           <div className="font-work-sans text-[36px] font-bold leading-[36px] ">
//             <p className="font-normal">Programs with </p>
//             <p> Gurudev in Boone</p>
//           </div>
//           <p className="my-4  text-[18px] font-normal">
//             {' '}
//             July 14-22, 2024 Registration Now Open
//           </p>
//           <a
//             href="https://artoflivingretreatcenter.org/sri-sri-ravi-shankar/gurudev-july-2024"
//             className="w-full"
//           >
//             <Button
//               className="mt-1 h-[62px]   bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest text-[white]"
//               fullWidth
//             >
//               Know more <FaArrowRight />
//             </Button>
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };
// const GurupurnimaAolWebsiteBannerMobile = () => {
//   const router = useRouter();
//   return (
//     <section className="h-[700px]">
//       <div className="relative aspect-[1140/530] size-full ">
//         <Image
//           className="object-cover object-top"
//           fill
//           src={`${router.basePath}/assets/images/GurupurnimaAolWebsiteBannerMobile.png`}
//           alt="programs with gurudev in Boone"
//           quality={100}
//           loading="eager"
//         />
//         {/* Added priority in this place for better page loading as the page is the first load */}
//         <div className="absolute bottom-0  mt-auto px-[31px] pb-[100px] text-center text-black">
//           <div className="font-work-sans text-[36px] font-bold leading-[36px]">
//             <p className="font-normal">GURUPURNIMA IN LA </p>
//             <p>WITH GURUDEV</p>
//           </div>
//           <p className="my-4 text-[18px] font-normal">
//             22 July, 5 PM | Los Angeles
//           </p>
//           <a
//             href="https://members.us.artofliving.org/us-en/ticketed-event/a04Vu000006wePpIAI?ticket={%22a08Vu00000ASSbYIAX%22:1}"
//             className="w-full"
//           >
//             <Button
//               className="mx-auto h-[62px] w-[300px] bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest"
//               color="primary"
//             >
//               Know more <FaArrowRight />
//             </Button>
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };
// const WcffAolWebsiteBannerMobile = () => {
//   const router = useRouter();
//
//   return (
//     <section className="flex h-[700px] justify-center">
//       <div className="relative aspect-[1140/530] size-full ">
//         <Image
//           className="object-cover object-top"
//           fill
//           src={`${router.basePath}/assets/images/wcf_film_festival_mobile.jpg`}
//           alt="programs with gurudev in Boone"
//           quality={100}
//           loading="eager"
//         />
//         <div className="absolute bottom-[72px] w-full text-center">
//           <a
//             href="https://www.worldculturefilmfestival.org/"
//             className=" size-full"
//           >
//             <Button
//               className="mx-auto h-[62px] w-[300px] bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest"
//               color="primary"
//             >
//               Know more <FaArrowRight />
//             </Button>
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };

// const SilenceRetreatDesktopSlide = () => {
//   const router = useRouter();
//   return (
//     <div className="relative aspect-[1140/530] size-full h-full">
//       <Image
//         className="object-cover object-top"
//         fill
//         src={`${router.basePath}/assets/images/silenceRetreat.jpg`}
//         alt="programs with gurudev in Boone"
//         quality={100}
//         loading="eager"
//       />
//       {/* Added priority in this place for better page loading as the page is the first load */}
//       <div className="absolute right-24 top-10 h-full w-[400px]  pt-[90px] text-[#31364C]">
//         <div className="font-work-sans text-[48px] font-bold leading-[50px] ">
//           <p className="font-normal">Silent Retreat </p>
//           <p> with Gurudev</p>
//         </div>
//         <p className="my-4   w-[90%] font-work-sans text-[16px] font-medium">
//           Experience profound rejuvenation at mountaintop Silent Retreat with
//           Gurudev, ideal for newcomers to silent retreats and SKY Breath
//           practice.
//         </p>
//         <a href="https://artoflivingretreatcenter.org/event/stepping-into-silence-with-gurudev/">
//           <Button
//             className="h-[62px] w-[300px] bg-gradient-to-r from-[#FFB800] to-[#FF9200] text-[14px] font-semibold tracking-widest"
//             color="primary"
//           >
//             Know more <FaArrowRight />
//           </Button>
//         </a>
//       </div>
//     </div>
//   );
// };
// const GurupoornimaAolWebsiteBanner = () => {
//   const router = useRouter();
//   return (
//     <div className="relative aspect-[1140/530] size-full">
//       <Image
//         className="object-cover object-top"
//         layout="fill"
//         src={`${router.basePath}/assets/images/GurupoornimaAolWebsiteBannerV2.png`}
//         alt="programs with gurudev in Boone"
//         quality={100}
//         loading="eager"
//       />
//       <div className="absolute left-[7%] top-[25%] h-full w-[532px]  text-[#31364C]">
//         <div className="space-y-4 text-center font-work-sans text-5xl font-normal leading-10">
//           <p>GURUPURNIMA IN LA</p>
//           <p className="font-bold">WITH GURUDEV</p>
//           <p className="pt-5 text-center font-work-sans text-4xl font-bold   leading-9">
//             22 July, 5 PM | Los Angeles
//           </p>
//         </div>
//         <div className=" absolute left-[25%] top-[53%] max-w-[90%] text-center text-[#31364C]">
//           <a href="https://members.us.artofliving.org/us-en/ticketed-event/a04Vu000006wePpIAI?ticket={%22a08Vu00000ASSbYIAX%22:1">
//             <Button
//               className="relative mx-auto   flex h-[57px]  w-[256px] items-center justify-center bg-gradient-to-r from-[#FFA41C] to-[#FBDC38] font-semibold tracking-widest text-white"
//               color="primary"
//             >
//               <div className="flex items-center">
//                 <p className="text-lg">Know more</p>
//                 <FaArrowRight className="ml-2" />
//               </div>
//             </Button>
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// const WcffAolWebsiteBanner = () => {
//   const router = useRouter();
//   return (
//     <div className="relative aspect-[1140/530] size-full">
//       <Image
//         className="object-cover object-top"
//         layout="fill"
//         src={`${router.basePath}/assets/images/WcffAolWebsiteBannerr-New.jpg`}
//         alt="programs with gurudev in Boone"
//         quality={100}
//         loading="eager"
//       />
//
//       {/* <div className="absolute left-[7%] top-[5%] h-full w-[532px]  text-[#FFFFFF] "> */}
//       {/* <img src={`${router.basePath}/assets/images/LogoYTremovebgpreview8.png`} alt="Description of your image" className='h-[62px] w-[140px] ml-[20%]'/> */}
//       {/* <div className="font-work-sans text-5xl font-normal leading-10 text-center space-y-4" > */}
//
//       <div className="flex">
//         {/* <span className="display: inline-block;">
//   <img src={`${router.basePath}/assets/images/Wlogo1.png`} alt="Description of your image" className='h-[94px] w-[81px]'/>
// </span> */}
//         {/* font-big-shoulders-text text-[90px] font-semibold leading-83 tracking-[-9px] text-right w-[442px] h-[190px] absolute top-88 */}
//         {/* <span className='mt-9 font-Big Shoulders text-[59px] font-semibold  text-center '>
//   ORLD CULTURE
// </span> */}
//         {/* </div> */}
//
//         {/* <p className="font-Big Shoulders Text text-[45px] leading-[65px] tracking-[-5px]">FILM FESTIVAL</p>
//     <p className=' font-arimo text-[20px] font-normal leading-[23px] text-center bg-gradient-to-r from-red-500 to-purple-600 w-[433px] h-[44px] p-3 ml-10'>ENTERTAINMENT THAT UPLIFTS</p>
//     <span className="font-Arimo text-[20px] font-normal leading-9 text-center    pt-5">LOS ANGELES </span><span className='font-Arimo text-[20px] font-normal leading-9 text-center text-[#FF66C4]'>JULY 25-28</span> */}
//         {/* </div> */}
//       </div>
//       <div className="absolute bottom-[9%] left-[14%] max-w-[90%] text-center text-[#31364C]">
//         <a href="https://www.worldculturefilmfestival.org/">
//           <Button
//             className="relative mx-auto flex h-[57px] w-[256px] items-center justify-center bg-gradient-to-r from-[#FFB800] to-[#FF9200] font-semibold tracking-widest text-white"
//             color="primary"
//           >
//             <div className="flex items-center">
//               <p className="text-lg">Know more</p>
//               <FaArrowRight className="ml-2" />
//             </div>
//           </Button>
//         </a>
//       </div>
//     </div>
//   );
// };
const HeroSlider = () => {
  const { isOpen, onOpenChange } = useDisclosure();

  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const screenWidth = useScreenWidth();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,
    cssEase: 'linear',
  };

  const { getslidesData } = useHeroSlidesData();
  const slidesData = getslidesData();

  return (
    <div className="w-full">
      {screenWidth > 700 ? (
        <div className={`${screenWidth > 1024 ? 'px-[140px]' : ''}`}>
          <Slider {...settings}>
            {/* <GurupoornimaAolWebsiteBanner /> */}
            {/* <WcffAolWebsiteBanner /> */}
            {/* <SilenceRetreatDesktopSlide /> */}
            {/* <BooneDesktopSlide /> */}
            {slidesData.map((slideData: HeroSlidePropsTypes) => (
              <Slide {...slideData} key={slideData.id} />
            ))}
          </Slider>

          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="5xl"
            hideCloseButton
          >
            <ModalContent>
              {(onClose) => (
                <div className="relative">
                  {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center opacity-75">
                      <div className="size-12 animate-spin rounded-full border-x-4 border-x-[#FF9200]" />
                    </div>
                  )}
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div
                    className="absolute right-5 top-0 z-10 size-[20px] cursor-pointer rounded-full p-4 text-white"
                    onClick={onClose}
                    onKeyUp={onClose}
                    role="button"
                    tabIndex={0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="25"
                      height="25"
                      viewBox="0 0 50 50"
                      fill="#FFFFFF"
                    >
                      <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
                    </svg>
                  </div>
                  <div className="aspect-h-9 aspect-w-16">
                    <iframe
                      width={window.innerWidth}
                      height="700"
                      src="https://www.youtube.com/embed/NAx9L5uTdqg?autoplay=1"
                      onLoad={handleIframeLoad}
                      title="World Culture Festival IV"
                      allow="autoplay; fullscreen; picture-in-picture"
                    />
                  </div>
                </div>
              )}
            </ModalContent>
          </Modal>
        </div>
      ) : (
        <div id="hero-slider">
          <Slider {...settings}>
            {/* <GurupurnimaAolWebsiteBannerMobile /> */}
            {/* <WcffAolWebsiteBannerMobile /> */}
            {/* <MobileSlide {...silenceRetreatData} /> */}
            {/* <BooneMobileSlide /> */}
            {slidesData.map((slideData: HeroSlidePropsTypes) => (
              <MobileSlide {...slideData} key={slideData.id} />
            ))}
          </Slider>
          <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center">
            <ModalContent>
              {(onClose) => (
                <div className="relative">
                  {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center opacity-75">
                      <div className="size-12 animate-spin rounded-full border-x-4 border-x-[#FF9200]" />
                    </div>
                  )}
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div
                    className="absolute right-5 top-0 z-10 size-[20px] cursor-pointer rounded-full p-4 text-white"
                    onClick={onClose}
                    onKeyUp={onClose}
                    role="button"
                    tabIndex={0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="25"
                      height="25"
                      viewBox="0 0 50 50"
                      fill="#FFFFFF"
                    >
                      <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
                    </svg>
                  </div>
                  <div className="aspect-h-9 aspect-w-16">
                    <iframe
                      width={window.innerWidth}
                      height="700"
                      src="https://www.youtube.com/embed/NAx9L5uTdqg?autoplay=1"
                      onLoad={handleIframeLoad}
                      title="World Culture Festival IV"
                      allow="autoplay; fullscreen; picture-in-picture"
                    />
                  </div>
                </div>
              )}
            </ModalContent>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default HeroSlider;
